/* corporate App.css */

:root {
  --main-color: #f0b0d7;
  --background-color: white;
  --main-button-color: #f0b0d7;
  --main-button-color-focused: #d0e0f0;
  --monacotto-color: #2097c0;
  --monacotto-color-pale: #a7e0ff;
  --monacotto-color-almostWhite: #f3faff;
  --KOM-color: #b08030;
}

:focus {
  outline: none;
}

ul {
  list-style: none;
}

.App {
  position: relative;
  flex-direction: column;
  font-size: 1em;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

/* ----- specific purpose ------------------------------------- */

.main {
  position: relative;
  min-height: 95vh;
  padding-bottom: 2em;
  box-sizing: border-box;
}

.mainKOM {
  position: relative;
  min-height: 96vh;
  padding-bottom: 4vh;
  box-sizing: border-box;
}

.appConnectBottomUp {
  width: 98vw;
  height: 8em;
  box-sizing: border-box;
  background-color: transparent;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 98vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  background-color: var(--main-button-color-focused);
}

.footerPad {
}

.lpMainImage {
  width: 40em;
}

.cardImage {
  width: 19em;
}

.cardImageSmall {
  width: 10em;
}

.cardImageLarge {
  width: 38em;
}

.cardImageFullWidth {
  width: 96vw;
}

.cardImageSP {
  width: 37vw;
}

.cardImageSmallSP {
  width: 4em;
}

.cardImageAbout {
  height: 30em;
  width: auto;
}

.cardImageAboutSP {
  width: 90vw;
  height: auto;
}

.itemImage {
  width: 21em;
}

.blankSlot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18em;
  height: 18em;
  margin: 1em;
  border-radius: 0.5em;
}

.blankSlotSP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3.2em;
  height: 3.2em;
  margin: 0.1em;
  border-radius: 0.3em;
}

.mainImageContainer {
  position: relative;
  width: 24em;
  height: 18em;
  border: 1px dotted #888;
  background-color: transparent;
}

.mainImageAbsolute {
  width: 24em;
  height: 18em;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
  object-fit: cover;
}

.gate {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 25em;
  max-width: 95%;
  min-height: 25em;
  margin: 1em;
  padding: 0.5em;
  border: none;
  /*
  border: 1px solid var(--monacotto-color);
  border-radius: 0.3em;
  */
  box-shadow: none;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  cursor: pointer;
}

.gate:hover {
  box-shadow: 4px 4px 4px 2px var(--monacotto-color-pale);
  transform: translateY(-4px);
}

.gateSmallScreen {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 98vw;
  min-height: 25em;
  padding: 1vw;
  border: none;
  /*
  border: 1px solid var(--monacotto-color);
  border-radius: 0.3em;
  */
  box-shadow: none;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  cursor: pointer;
}

.gateSmallScreen:hover {
  box-shadow: 4px 4px 4px 2px var(--monacotto-color-pale);
  transform: translateY(-4px);
}

.mainImage {
  display: block;
  width: 24em;
  height: 18em;
  object-fit: cover;
}

.mainImageSmallScreen {
  display: block;
  width: 96vw;
  height: 72vw;
  object-fit: cover;
}

.howToUseMain {
  /*
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 120em; */
  margin: 1em;
  border: 3px double var(--main-color);
}

.howToUseText {
  /*
  position: absolute;
  top: 28em;
  left: 55em;
  width: 62em;
  height: 35em;
  */
  border: 3px dashed var(--main-button-color-focused);
  margin: 1em;
  padding: 1em;
}

.boxCard {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  /*
  min-width: 20em;
  min-height: 30em;
  */
  margin: 1em;
  padding: 0.5em;
  border: none;
  /*
  border-left: none;
  border-top: none;
  border-right: 1px solid var(--monacotto-color);
  border-bottom: 1px solid var(--monacotto-color);
  border-radius: 0.3em;
  */
  box-shadow: none;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  cursor: pointer;
}

.boxCard:hover {
  box-shadow: 4px 4px 4px 2px var(--monacotto-color-pale);
  transform: translateY(-4px);
}

.boxCardSmall{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  /*
  min-width: 12em;
  min-height: 16em;
  */
  margin: 1em;
  padding: 0.5em;
  border: none;
  /*
  border-left: none;
  border-top: none;
  border-right: 1px solid var(--monacotto-color);
  border-bottom: 1px solid var(--monacotto-color);
  border-radius: 0.3em;
  */
  box-shadow: none;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  cursor: pointer;
}

.boxCardSmall:hover {
  box-shadow: 4px 4px 4px 2px var(--monacotto-color-pale);
  transform: translateY(-4px);
}

.boxCardSP{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  /*
  min-width: 12em;
  min-height: 16em;
  */
  margin: 0.1em;
  padding: 0.1em;
  border: none;
  /*
  border-left: none;
  border-top: none;
  border-right: 1px solid var(--monacotto-color);
  border-bottom: 1px solid var(--monacotto-color);
  border-radius: 0.3em;
  */
  box-shadow: none;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  cursor: pointer;
}

.boxCardSP:hover {
  box-shadow: 4px 4px 4px 2px var(--monacotto-color-pale);
  transform: translateY(-4px);
}

.boxHistory {
  min-height: 4em;
  min-width: 80em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
}

.boxPermission {
  min-height: 6em;
  min-width: 30em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
}

.boxDetail {
  min-width: 25em;
  margin: 0.5em;
  padding: 0.5em;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
}

.boxDetailSmallScreen {
  width: 98%;
  margin: 0.5em 0;
  padding: 0.1em;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
}

/*
.cottoRolling {
  width: 5em;
  height: 5em;
  background: url('./cottoRolling.png') no-repeat;
  background-size: 40em 5em;
  animation: slide 0.5s steps(8) infinite;
}
*/

@keyframes slide {
  to {
    background-position: -64em 0;
  }
}

/*
.cottoStill {
  width: 5em;
  height: 5em;
  background: url('./cottoRolling.png') no-repeat;
  background-size: 40em 5em;
}
*/

/*
.magicRollingCenter {
  display: flex;
  z-index: 15001;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 8em;
  height: 8em;
  background: url('./magicRollingConcatenated.png') no-repeat;
  background-size: 64em 8em;
  animation: slide 1s steps(8) infinite;
  / color: #803030; /
  opacity: 0.8;
  / padding: 0.5em 1em; /
  / background-color: #ffe3b3; /
  / border-radius: 5px; /
  pointer-events: none;
}
*/

.soldOut {
  padding: 0.5em 0.2em;
  border: 2px solid var(--main-color);
  border-radius: 0.5em;
  font-size: 1.5em;
  font-weight: bold;
  color: var(--main-color);
}

/*
.notificationBox {
  left: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  color: #803030;
  padding: 0.5em 1em;
  background-color: #ffe3b3;
  border-radius: 5px;
  word-break: break-all;
  pointer-events: none;
}
*/

.boxDuelText {
  position: relative;
  width: 31em;
  min-height: 33em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--main-color);
  border-radius: 0.3em;
  word-wrap: break-word;
  white-space: pre-wrap;
  background-color: white;
}

.sizeBoxDuelTextSP {
  width: 96vw;
  min-height: 30vh;
  margin-left: 1vw;
  margin-right: 1vw;
  box-sizing: border-box;
}

.duelField {
  /* width: 140em; */
  min-height: 32em;
  border: 1px solid var(--main-color);
}

.knightBox {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 20em;
  height: 30em;
  margin: 0.5em;
  padding: 0.5em;
  border-radius: 0.3em;
}

.widthKnightBoxSP {
  width: 45vw;
  margin-left: 1vw;
  margin-right: 1vw;
  box-sizing: border-box;
}

.heightKnightBoxSP {
  height: calc(64vw + 6em);
}

.heightEmblemSP {
  height: 50vw;
}

.boxDeck {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 85em;
  min-height: 18em;
  margin: 0.5em;
  padding: 0.5em;
  border: none;
  border-radius: 0.3em;
}

.boxDeckSmall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 85em;
  min-height: 16em;
  margin: 0.5em;
  padding: 0.5em;
  border: none;
  border-radius: 0.3em;
}

.boxMonatokaPoint {
  min-width: 10em;
  min-height: 15em;
  margin: 0.5em;
  padding: 0.5em;
  border: none;
  border-radius: 0.3em;
}

.classA {
  border: 3px solid #ff3030;
}

.classB {
  border: 3px solid #3030ff;
}

.classBackGroundA {
  background-color: rgba(255,47,47,0.5);
}

.classBackGroundB {
  background-color: rgba(47,47,255,0.5);
}

.borderColorA {
  border-color: #ff3030;
}

.borderColorB {
  border-color: #3030ff;
}

.monsterStatus {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 150;
}

/* ----- functional group ------------------------------------- */

/* +++ box +++ */

.box1 {
  position: relative;
  width: 272px;
  height: 2.2em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.box2 {
  position: relative;
  width: 25em;
  min-height: 1em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--main-color);
  border-radius: 0.3em;
  word-wrap: break-word;
  background-color: white;
}

.box2Sp {
  position: relative;
  width: 95vw;
  min-height: 2em;
  padding: 0.5em;
  border: 1px solid var(--main-color);
  border-radius: 0.3em;
  word-wrap: break-word;
  background-color: white;
  box-sizing: border-box;
}

.box3 {
  position: relative;
  min-height: 1.5em;
  margin: 1em;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: transparent;
  cursor: pointer;
}

.box3Sp {
  position: relative;
  min-height: 1.5em;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: transparent;
  cursor: pointer;
}

.box4 {
  position: relative;
  width: 24em;
  max-width: 95%;
  height: 10em;
  border: 1px solid var(--monacotto-color);
  border-radius: 0.5em;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
}

.box4Sp {
  position: relative;
  width: 100%;
  height: 10em;
  border: 1px solid var(--monacotto-color);
  border-radius: 0.5em;
  background-color: transparent;
  cursor: pointer;
}

.boxMonacotto1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
  text-align: center;
  font-size: 1em;
}

.boxMonacotto1SmallScreen {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 97vw;
  margin: 0.5em 0;
  padding: 0.1em;
  border: none;
  border-radius: 0.3em;
  background-color: var(--monacotto-color-almostWhite);
  text-align: left;
  font-size: 1em;
}

.box4chars {
  position: relative;
  width: 4em;
  height: 2.2em;
  margin: 2px;
  border-bottom: 1px solid black;
  border-radius: 0.3em;
  background-color: rgba(230,230,240,0.5);
}

.dummyPadButton1 {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  border: none;
  background-color: white;
}

/* +++ button +++ */

.button1 {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  box-shadow: none;
  transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
  background-image: url(./wallPaper01.png);
  /* background-color: var(--main-color); */
  cursor: pointer;
}

.button1:focus {
  border: 2px solid black;
  /*
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border-radius: 0.3em;
  background-color: var(--main-button-color-focused);
  cursor: pointer;
  */
}

.button1:hover {
  box-shadow: 2px 2px 2px 1px var(--monacotto-color-pale);
  transform: translateY(-2px);
}

.button1Dummy {
  height: 2.4em;
  width: 2.4em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  box-shadow: none;
  background-image: url(./wallPaper01.png);
}

.button2 {
  height: 3em;
  width: 8em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  box-shadow: none;
  transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
  background-image: url(./wallPaper01.png);
  /* background-color: var(--main-button-color); */
  cursor: pointer;
}

.button2:focus {
  border: 2px solid black;
}

.button2:hover {
  box-shadow: 2px 2px 2px 1px var(--monacotto-color-pale);
  transform: translateY(-2px);
}

.button2Dummy {
  height: 3em;
  width: 8em;
  margin: 0.5em;
  border: none;
  background-color: transparent;
}

.button3 {
  height: 4em;
  min-width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-color);
  cursor: pointer;
}

.button3:focus {
  height: 4em;
  min-width: 6em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color-focused);
  cursor: pointer;
}

.button4 {
  height: 2em;
  width: 2em;
  margin: 0.5em;
  padding: 0;
  : none;
  border-radius: 0.3em;
  background-color: var(--main-button-color);
  cursor: pointer;
}

.button4:focus {
  height: 2em;
  width: 2em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  background-color: var(--main-button-color-focused);
  cursor: pointer;
}

.buttonMainColor {
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  box-shadow: none;
  transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
  background-image: url(./wallPaper01.png);
  /* background-color: var(--main-color); */
  cursor: pointer;
}

.buttonMainColor:focus {
  border: 2px solid black;
}

.buttonMainColor:hover {
  box-shadow: 2px 2px 2px 1px var(--monacotto-color-pale);
  transform: translateY(-2px);
}

/* +++ text line +++ */

.textLine1 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 50%;
  width: 96%;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

/* +++ text area +++ */

.textArea1 {
  position: absolute;
  left: 2%;
  bottom: 0;
  z-index: 100;
  height: 90%;
  width: 96%;
  resize: none;
  padding: 0em 0em;
  border: none;
  background-color: transparent;
  font-size: 1em;
}

/* +++ label +++ */

.label1Unfilled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
}

.label1Filled {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  padding: 0em 0.5em;
  font-size: 0.5em;
}

/* +++ popup +++ */

.popupBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.popupBackgroundMultiLayer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(9999 + var(--popupLayer) * 10);
}

.popup {
  position: relative;
  max-height: 90%;
/*  max-width: 85%; */
  max-width: 800px;
  z-index: 10001;
  border-radius: 10px;
  background: #fff;
  padding: 1.5em 1em;
  word-wrap: break-word;
  overflow-y: auto;
}

.popupMultiLayer {
  position: relative;
  max-height: 92%; /* 90% */
  max-width: 95%; /* 85% */
  /* max-width: var(--maxWidth); */
  z-index: calc(10001 + var(--popupLayer) * 10);
  border-radius: 10px;
  background: #fff;
  padding: 1.5em 1em;
  word-wrap: break-word;
  overflow-y: auto;
}

.popupMultiLayerSmallScreen {
  position: relative;
  max-height: 90%;
  width: 99vw;
  /* max-width: 99vw; */
  /* max-width: var(--maxWidth); */
  z-index: calc(10001 + var(--popupLayer) * 10);
  border-radius: 10px;
  background: #fff;
  padding: 1.5em 0;
  word-wrap: break-word;
  overflow-y: auto;
}

.closePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: block;
  background: rgba(0,0,0,.3);
  cursor: pointer;
}

.closePopupMultiLayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: calc(10000 + var(--popupLayer) * 10);
  display: block;
  background: rgba(0,0,0,.3);
  cursor: pointer;
}

.topRightClose {
  position: absolute;
  top: 0em;
  right: 0em;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: transparent;
  color: #803030;
  font-size: 1em; 
}

/* +++ tooltip +++ */

.tooltip {
  position: absolute;
  left: 30%;
  top: 115%;
  z-index: 200;
  display: none;
  padding: 0.5em 1em;
  border: 1.5px solid var(--main-color);
  border-radius: 0.3em;
  background-color: var(--background-color);
}

.tooltipRight {
  position: absolute;
  left: 30%;
  top: 115%;
  z-index: 200;
  display: none;
  padding: 0.5em 1em;
  border: 1.5px solid var(--main-color);
  border-radius: 0.3em;
  background-color: var(--background-color);
}

.tooltipLeft {
  position: absolute;
  left: -30%;
  top: 115%;
  z-index: 200;
  display: none;
  padding: 0.5em 1em;
  border: 1.5px solid var(--main-color);
  border-radius: 0.3em;
  background-color: var(--background-color);
}

.messageFade-appear-done {
  opacity: 0;
}

.messageFade-enter {
  /* display: flex; */
  z-index: 20001;
  position: fixed;
  /*
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  */
  width: fit-content;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #803030;
  opacity: 1;
  padding: 0.5em 1em;
  background-color: #ffe3b3;
  border-radius: 5px;
  /* word-break: break-all; */
  word-wrap: break-word;
  pointer-events: none;
}

.messageFade-enter-active {
  /*
  position: relative;
  width: 25em;
  min-height: 1em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--main-color);
  border-radius: 0.3em;
  word-wrap: break-word;
  background-color: white;
  */
  z-index: 20001;
  position: fixed;
  /*
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  */
  width: fit-content;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: #803030;
  opacity: 0;
  transition: opacity 2000ms ease-out 3000ms;
  padding: 0.5em 1em;
  background-color: #ffe3b3;
  border-radius: 5px;
  /* word-break: break-all; */
  word-wrap: break-word;
  pointer-events: none;
}

.messageFade-enter-done {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade-exit {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade-exit-done {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade2-appear-done {
  opacity: 0;
}

.messageFade2-enter {
  z-index: 21000;
  position: fixed;
  /*
  left: 0;
  top: 0;
  */
  width: 100%;
  height: 100%;
  /*
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  */
  opacity: 1;
  pointer-events: none;
}

.messageFade2-enter-active {
  /*
  position: relative;
  width: 25em;
  min-height: 1em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid var(--main-color);
  border-radius: 0.3em;
  word-wrap: break-word;
  background-color: white;
  */
  z-index: 21000;
  position: fixed;
  /*
  left: 0;
  top: 0;
  */
  width: 100%;
  height: 100%;
  /*
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  */
  opacity: 0;
  transition: opacity 2000ms ease-out 3000ms;
  pointer-events: none;
}

.messageFade2-enter-done {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade2-exit {
  display: none;
  color: #803030;
  opacity: 0;
}

.messageFade2-exit-done {
  display: none;
  color: #803030;
  opacity: 0;
}

/* +++ screen +++ */

.screen140 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 140;
  background: rgba(256,256,256,.5);
}

/* ----- single function ------------------------------------- */

/* +++ flex +++ */

.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.flexColumnReverse {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.justifyContentCenter {
  justify-content: center;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.alignItemsFlexEnd {
  align-items: flex-end;
}

/* +++ margin +++ */

.margin0p5 {
  margin: 0.5em;
}

.margin1 {
  margin: 1em;
}

.margin2 {
  margin: 2em;
}

.marginTop4 {
  margin-top: 4em;
}

.marginTop2 {
  margin-top: 2em;
}

.marginTop1 {
  margin-top: 1em;
}

.marginTop0p5 {
  margin-top: 0.5em;
}

.marginTopBottom0p1 {
  margin-top: 0.1em;
  margin-bottom: 0.1em;
}

.marginTopBottom0p3 {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.marginTopBottom0p5 {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.marginTopBottom1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.marginBottom0p5 {
  margin-bottom: 0.5em;
}

.marginBottom1 {
  margin-bottom: 1em;
}

.marginBottom2 {
  margin-bottom: 2em;
}

.marginBottomM0p5 {
  margin-bottom: -0.5em;
}

.marginLeft0p5 {
  margin-left: 0.5em;
}

.marginLeft1 {
  margin-left: 1em;
}

.marginRight0p5 {
  margin-right: 0.5em;
}

.marginRight1 {
  margin-right: 1em;
}

.marginSide0 {
  margin-left: 0;
  margin-right: 0;
}

.marginSide0p1 {
  margin-left: 0.1em;
  margin-right: 0.1em;
}

.marginSide0p2 {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.marginSide0p5 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.marginSide1 {
  margin-left: 1em;
  margin-right: 1em;
}

.marginSide2 {
  margin-left: 2em;
  margin-right: 2em;
}

.marginSide2p5vw {
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}

/* *** padding +++ */

.padding0p3 {
  padding: 0.3em;
}

.padding0p5 {
  padding: 0.5em;
}

.padding1 {
  padding: 1em;
}

.padding2 {
  padding: 2em;
}

.paddingRight0p5 {
  padding-right: 0.5em;
}

.paddingRight1 {
  padding-right: 1em;
}

.paddingRight5 {
  padding-right: 5em;
}

.paddingLeft0p5 {
  padding-left: 0.5em;
}

.paddingLeft1 {
  padding-left: 1em;
}

.paddingLeft5 {
  padding-left: 5em;
}

.paddingSide0p1 {
  padding: 0.1em;
}

.paddingSide0p5 {
  padding: 0.5em;
}

.paddingSide1 {
  padding-left: 1em;
  padding-right: 1em;
}

.paddingTop3 {
  padding-top: 3em;
}

.paddingBottom1 {
  padding-bottom: 1em;
}

.paddingBottom2 {
  padding-bottom: 2em;
}

/* +++ size +++ */

.size2x2 {
  height: 2.2em;
  width: 2.2em;
}

.size2x2Large {
  height: 2.5em;
  width: 2.5em;
}

.size1p7x1p7 {
  height: 1.7em;
  width: 1.7em;
}

.width0p7 {
  width: 0.7em;
}

.width1 {
  width: 1em;
}

.width2p1 {
  width: 2.1em;
}

.width2p4 {
  width: 2.4em;
}

.width3 {
  width: 3em;
}

.width4 {
  width: 4em;
}

.width6 {
  width: 6em;
}

.width8 {
  width: 8em;
}

.width10 {
  width: 10em;
}

.width14 {
  width: 14em;
}

.width20 {
  width: 20em;
}

.width24 {
  width: 24em;
}

.width35 {
  width: 35em;
}

.width50 {
  width: 50em;
}

.width60 {
  width: 60em;
}

.width80 {
  width: 80em;
}

.width104 {
  width: 104em;
}

.widthDiv2 {
  width: 40%;
}

.widthDiv3 {
  width: 25%;
}

.width70P {
  width: 70%;
}

.width48vw {
  width: 48vw;
  box-sizing: border-box;
}

.width60vw {
  width: 60vw;
  box-sizing: border-box;
}

.maxWidth60vw {
  max-width: 90vw;
  box-sizing: border-box;
}

.width90vw {
  width: 90vw;
  box-sizing: border-box;
}

.maxWidth90vw {
  max-width: 90vw;
  box-sizing: border-box;
}

.width93vw {
  width: 93vw;
  box-sizing: border-box;
}

.width95vw {
  width: 95w;
  box-sizing: border-box;
}

.maxWidth95vw {
  max-width: 95vw;
  box-sizing: border-box;
}

.width96vw {
  width: 96vw;
  box-sizing: border-box;
}

.width98vw {
  width: 98vw;
  box-sizing: border-box;
}

.maxWidth98vw {
  max-width: 98vw;
  box-sizing: border-box;
}

.widthMax {
  width: 100%;
  box-sizing: border-box;
}

.maxWidthMax {
  max-width: 100%;
  box-sizing: border-box;
}

.width100vw {
  width: 100vw;
  box-sizing: border-box;
}

.maxWidth100vw {
  max-width: 100vw;
  box-sizing: border-box;
}

.width98PC {
  width: 98%;
  box-sizing: border-box;
}

.width96PC {
  width: 96%;
  box-sizing: border-box;
}

.width90PC {
  width: 90%;
}

.width85PC {
  width: 85%;
}

.width75PC {
  width: 75%;
}

.width44PC {
  width: 44%;
}

.width40PC {
  width: 40%;
}

.widthMin5 {
  min-width: 5em; 
}

.widthMin7 {
  min-width: 7em; 
}

.widthMin10 {
  min-width: 10em; 
}

.widthMin12 {
  min-width: 12em; 
}

.widthMin13 {
  min-width: 13em; 
}

.widthMin15 {
  min-width: 15em; 
}

.widthMin20 {
  min-width: 20em; 
}

.widthMin25 {
  min-width: 25em; 
}

.widthMin50vw {
  min-width: 50vw;
  box-sizing: border-box;
}

.widthMin85 {
  min-width: 85em;
}

.widthMax25 {
  max-width: 25em; 
}

.widthVW {
  width: 98vw;
  box-sizing: border-box;
}

.heightMax {
  height: 100%;
  box-sizing: border-box;
}

.height0p7 {
  height: 0.7em;
}

.height1 {
  height: 1em;
}

.height1p2 {
  height: 1.2em;
}

.height1p6 {
  height: 1.6em;
}

.height4 {
  height: 4em;
}

.height15 {
  height: 15em;
}

.height27 {
  height: 27em;
}

.height30PC {
  height: 30%;
}

.height70PC {
  height: 70%;
}

.height2p2 {
  height: 2.2em;
}

.heightMin1 {
  min-height: 1em; 
}

.heightMin1p3 {
  min-height: 1.3em; 
}

.heightMin2 {
  min-height: 2em; 
}

.heightMin3 {
  min-height: 3em; 
}

.heightMin4 {
  min-height: 4em; 
}

.invisibleCheckBox {
  display: none;
}

.invisibleCheckBox:checked + .label1Unfilled {
  font-size: 0.5em;
}

.invisibleCheckBox:checked +.box1 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box2 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box3 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.box4 {
  background-color: rgba(183,183,183,0.5);
}

.invisibleCheckBox:checked +.tooltip {
  display: flex;
}

.invisibleCheckBox:checked +.tooltipRight {
  display: flex;
}

.invisibleCheckBox:checked +.tooltipLeft {
  display: flex;
}

/* +++ box sizing +++ */

.borderBox {
  box-sizing: border-box;
}

/* +++ color +++ */

.colorRed {
  color: red;
}

.backgroundColorWhite {
  background-color: white;
}

.backgroundColorMonacotto {
  background-color: var(--monacotto-color);
}

.backgroundColorMonacottoPale {
  background-color: var(--monacotto-color-pale);
  background-image: none;
}

.backgroundColorMonacottoAlmostWhite {
  background-color: var(--monacotto-color-almostWhite);
  background-image: none;
}

.backgroundColorRed {
  background-color: red;
}

.backgroundColorMagic {
  background-image: none;
  background-color: rgba(16, 165, 90, 0.35);
}

.backgroundColorTransparent {
  background-color: transparent;
}

.backgroundImageKOM {
  background-image: url(./wallPaper01.png);
}

.backgroundImageNone {
  background-image: none;
}

/* +++ text +++ */

.font0p5 {
  font-size: 0.5em;
}

.font0p7 {
  font-size: 0.7em;
}

.font1p5 {
  font-size: 1.5em;
}

.font2 {
  font-size: 2em;
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: Right;
}

.fontHyperLink {
  font-size: 1.3em;
  color: #8090f0;
}

.breakWord {
  word-wrap: break-word;
}

.breakAll {
  word-break: break-all;
}

.preWrap {
  white-space: pre-wrap;
}

.lineHeight1 {
  line-height: 1;
}

/* +++ display +++ */

.invisible {
  display: none;
}

.displayBlock {
  display: block;
}

/* +++ position +++ */

.relative {
  position: relative;
}

/* +++ border +++ */

.border{
  border: 1px solid green;
}

.borderNone {
  border: none;
}

.borderSelected {
  border: 2px solid var(--main-color);
}

.borderCircleMonacotto {
  border: 1px solid var(--monacotto-color);
  border-radius: 50%;
}

.borderMonacotto {
  border: 1px solid var(--monacotto-color);
  border-radius: 0.5em;
}

.borderMonacottoPale {
  border: 1px solid var(--monacotto-color-pale);
  border-radius: 0.5em;
}

.borderKOM {
  border: 1px solid var(--KOM-color);
  border-radius: 0.5em;
}

.borderKOM2pxBorder {
  border: 2px solid var(--KOM-color);
  border-radius: 0.5em;
}

.borderKOMFatBorder {
  border: 3px solid var(--KOM-color);
  border-radius: 0.5em;
}

.borderFat {
  border-width: 3px;
  border-style: solid;
}

.borderThin {
  border-width: 1px;
  border-style: solid;
}

.borderRadius2 {
  border-radius: 2px
}

.borderRadius0p3 {
  border-radius: 0.3em
}

.borderRadius0p5 {
  border-radius: 0.5em
}

.borderRadiusCircle {
  border-radius: 50%
}

.borderTopBottom {
  border-top: 0.2em solid var(--monacotto-color);
  border-bottom: 0.2em solid var(--monacotto-color);
  border-radius: 0.5em;
  padding: 0.5em;
}

.borderBottom {
  border-bottom: 0.2em solid var(--monacotto-color);
  border-radius: 0.5em;
  padding: 0.2em;
}

.borderLink {
  border-top: none;
  border-bottom: 2px solid blue;
  border-left: none;
  border-right: none;
}

/* +++ cursor +++ */

.cursor {
  cursor: pointer;
}

/* +++ scroll +++ */

.scrollY {
  overflow-y: scroll;
}

/* +++ z-index +++ */

.z150Center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 150;
}

.z200Left {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  z-index: 200;
}

.z200Right {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  z-index: 200;
}

.z200TopRight {
  position: absolute;
  top: 5%;
  right: 5%;
  /* transform: translateX(-50%); */
  z-index: 200;
}

.z200BottomRight {
  position: absolute;
  bottom: 5%;
  right: 5%;
  /* transform: translateX(-50%); */
  z-index: 200;
}

.z21000 {
  z-index: 21000;
}

.pointerEventsNone {
  pointer-events: none;
}

.boxShadow01 {
  box-shadow: 2px 2px 2px 1px var(--monacotto-color-pale);
}

/* +++ transition +++ */

.riseOut2 {
  box-shadow: 0px 1px 0px 0px var(--monacotto-color-pale);
  /* box-shadow: none; */
  transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
}

.riseOut2:hover {
  box-shadow: 2px 2px 2px 1px var(--monacotto-color-pale);
  transform: translateY(-2px);
}

.riseOut2Half {
  box-shadow: none;
  transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
}

.riseOut2Half:hover {
  box-shadow: 2px 2px 2px 1px var(--monacotto-color-pale);
  transform: translateY(-2px);
  /* transform: translateX(-50%) translateY(-2px); */
}

.backgroundColorUp {
  /* background-color: transparent; */
  background-image: none;
  /* transition: background-image 0.3s ease 0s; */
}

.backgroundColorUp:hover {
  /* background-color: var(--KOM-color); */
  background-image: url(./wallPaper01.png);
}

/* +++ media query +++ */

@media screen and (max-width: 1079px) {
  .invisibleSp {
    display: none;
  }

  .visibleLargeOrMore {
    display: none;
  }
}

@media screen and (min-width: 1080px) {
  .invisiblePc {
    display: none;
  }

  .visibleMiddleOrLess {
    display: none;
  }
}

@media screen and (max-width: 831px) {
  .visibleMiddleOrMore {
    display: none;
  }
}

@media screen and (min-width: 832px) {
  .visibleSmallOrLess {
    display: none;
  }
}


.button2Resp {
  height: 3em;
  width: 8em;
  margin: 0.5em;
  padding: 0;
  border: none;
  border-radius: 0.3em;
  box-shadow: none;
  transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
  background-image: url(./wallPaper01.png);
  /* background-color: var(--main-button-color); */
  cursor: pointer;
}

.button2Resp:focus {
  border: 2px solid black;
}

.button2Resp:hover {
  box-shadow: 2px 2px 2px 1px var(--monacotto-color-pale);
  transform: translateY(-2px);
}

.selectDeckDeckNo {
  min-width: 7em; 
  margin-left: 1em;
  margin-right: 1em;
}

.selectDeckDeckName {
  min-width: 25em; 
  margin-left: 1em;
  margin-right: 1em;
}

@media screen and (max-width: 831px) {
  .button2Resp {
    min-height: 4em;
    width: 96%;
    margin: 0.5em;
    padding: 0;
    border: none;
    border-radius: 0.3em;
    box-shadow: none;
    transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
    background-image: url(./wallPaper01.png);
    /* background-color: var(--main-button-color); */
    font-size: 0.7em;
    cursor: pointer;
  }

  .selectDeckDeckNo {
    width: 100%;
    min-height: 1em;
    box-sizing: border-box;
    padding-left: 1em;
  }

  .selectDeckDeckName {
    width: 100%;
    min-height: 1em;
    box-sizing: border-box;
    padding-left: 1em;
  }
}

